import * as React from "react";
import Container from "../theme/container";
import Heading from "../theme/heading";
import Button from "../theme/button";

import "../styles.css"

const CallToAction = ({heading, buttonPrompt, prompt}) => {
    return (
        <section className="callToActionRoot" >
            <Container>
                <Heading as="h1">{heading}</Heading>
                <Heading as="h2" secondary>{prompt}</Heading>
                <Button href="#contact" disabled="disabled" text={buttonPrompt}></Button>
            </Container>
        </section>
    );
}


export default CallToAction
// EOF