import * as React from "react";
import Container from "../theme/container";
import Heading from "../theme/heading";

import "../styles.css"
import * as styles from "./services.module.css"


const Services = ({ heading, services }) => {
    return (
        <section className="callToActionRoot" >
            <Container>
                <Heading align="center" as="h1">{heading}</Heading>
                <br/>
                <div className={styles.content}>
                    {
                        services.map((service) => <ServiceContent key={service.id} {...service} />)
                    }
                </div>
            </Container>
        </section>
    );
}

const ServiceContent = (service) => {
    return (
        <div className={styles.contentCard}>
            <center>
            <img src={service.imageURL} height="150" alt="" />
                <p className={styles.contentHeading}>
                    {service.title}
                </p>
                <p>{service.details}</p>
            </center>
        </div>
    )
}

export default Services
// EOF