import * as React from "react"
import Layout from "../components/layout";

// Styles
import "../styles.css"

// Site Content
import Content from "../content";

// Sections
import CallToAction from "../sections/callToAction";
import Services from "../sections/services";
import Contact from "../sections/contact";

const IndexPage = () => {
    return (
        <>
            <Layout contact={Content.Contact}>
                <CallToAction
                    heading={Content.CallToAction.heading}
                    buttonPrompt={Content.CallToAction.buttonPrompt}
                    prompt={Content.CallToAction.prompt}
                />

                <Services
                    heading={Content.Services.heading}
                    services={Content.Services.services}
                />

                <Contact
                    heading={Content.Contact.heading}
                    prompt={Content.Contact.prompt}
                    buttonPrompt={Content.Contact.buttonPrompt}
                    email={Content.Contact.email}
                    phone={Content.Contact.phone}
                    location={Content.Contact.location}
                />
            </Layout>
        </>
    );
}

export default IndexPage;